<template>
  <DxList @item-reordered="reorderItem" :items="items" :repaint-changes-only="true" key-expr="id">
    <DxItemDragging
      class="text-capitalize"
      data="items"
      :allow-reordering="true"
      :on-drag-start="onDragStart"
      group="items"
    />
  </DxList>
</template>

<script>
import DxList, { DxItemDragging } from "devextreme-vue/list";
import {} from "lodash";
import { capitalize } from "../../modules/helpers";
export default {
  components: {
    DxList,
    DxItemDragging
  },
  props: {
    value: {
      required: true
    },
    defaultValue: {
      required: false
    }
  },
  mounted() {
    if (!this.value && this.defaultValue) {
      this.$emit("input", this.defaultValue);
      this.items = this.defaultValue;
    }
  },
  computed: {
    items: {
      get() {
        if (!this.value && this.defaultValue) {
          return this.defaultValue.split(",").map(capitalize);
        }
        if (typeof this.value === "string") {
          return this.value.split(",").map(capitalize);
        }
        return this.value.map(capitalize);
      },
      set(value) {
        value = value.map(e => e.toLowerCase());
        if (typeof this.value === "string") {
          this.$emit("input", value?.join(","));
        } else {
          this.$emit("input", value);
        }

        return value;
      }
    }
  },
  methods: {
    onDragStart(e) {
      e.itemData = this[e.fromData][e.fromIndex];
    },
    reorderItem() {
      this.items = [...this.items];
    },
    onAdd(e) {
      const data = [...this[e.toData]];
      data.splice(e.toIndex, 0, e.itemData);
      this[e.toData] = data;
    },
    onRemove(e) {
      const data = [...this[e.fromData]];
      data.splice(e.fromIndex, 1);
      this[e.fromData] = data;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .dx-item-content.dx-list-item-content {
  & > * {
    text-transform: capitalize;
  }
}
</style>
