<template>
  <div>
    <div v-if="!showSettings">
      <h4>
        <strong>Warning:</strong> These settings are used for printing via File Drop to a PrintMate
        printer. If your lab does not do this, please disregard these settings, as they may cause
        issues in the app.
      </h4>
      <button class="btn btn-primary mb-2" @click.prevent="enableShowSettings">Configure</button>
    </div>
    <div v-else-if="shouldDisable">
      <h4 class="text-danger">
        File Drop printing has been disabled. Once you click Save, all previous settings will be
        lost.
      </h4>
      <button class="btn btn-success" @click="shouldDisable = false">Undo</button>
    </div>
    <div v-else class="mb-2">
      <text-input v-model="settings.printerName" label="Printer Name" />
      <text-input v-model="settings.url" label="URL" />
      <select-input
        v-model="settings.atAccessioning"
        :items="booleanOptions"
        label="File Drop at Accessioning"
      />
      <text-input v-model="trayOptions" label="Tray Options" @change="setDefaultTray" />
      <select-input
        v-model="settings.defaultTray"
        :items="defaultTrayOptions"
        label="Default Tray"
        valueExpr="displayName"
      />
      <text-input v-model="settings.printProcedure1" label="Procedure 1" />
      <text-input v-model="settings.printProcedure2" label="Procedure 2" />
      <button class="btn btn-danger" @click="handleDisableClick">Disable</button>
    </div>
  </div>
</template>

<script>
import SelectInput from "../common/SelectInput.vue";
import TextInput from "../common/TextInput.vue";
export default {
  components: { TextInput, SelectInput },
  props: ["value"],
  data() {
    return {
      settings: {
        printerName: "",
        url: "",
        atAccessioning: 0,
        trayOptions: [],
        defaultTray: "",
        printProcedure1: "",
        printProcedure2: ""
      },
      booleanOptions: [
        { displayName: "Yes", id: 1 },
        { displayName: "No", id: 0 }
      ],
      showSettings: false,
      shouldDisable: false
    };
  },
  mounted() {
    if (this.value) {
      this.settings = { ...this.settings, ...JSON.parse(this.value) };
      if (Object.keys(this.settings).length) {
        this.showSettings = true;
      }
    }
  },
  computed: {
    trayOptions: {
      get: function () {
        return this.settings?.trayOptions
          ? this.settings?.trayOptions.map(e => e.displayName).join(",")
          : "";
      },
      set: function (value) {
        if (this.settings?.trayOptions) {
          this.settings.trayOptions = value.split(",").map(e => {
            return { displayName: e };
          });
        } else {
          this.trayOptions = [];
        }
      }
    },
    defaultTrayOptions() {
      return this.trayOptions.split(",").map(e => {
        return { displayName: e };
      });
    }
  },
  methods: {
    enableShowSettings() {
      if (!this.settings) {
        this.settings = {
          printerName: "",
          url: "",
          atAccessioning: 0,
          trayOptions: []
        };
      }
      this.showSettings = true;
    },
    setDefaultTray() {
      const defaultTrayArray = this.defaultTrayOptions.map(e => e.displayName);
      if (
        (!this.settings.defaultTray && defaultTrayArray.length) ||
        !this.defaultTrayArray.includes(this.settings.defaultTray)
      ) {
        this.settings.defaultTray = defaultTrayArray[0];
      }
    },
    async handleDisableClick() {
      const confirm = await window.confirm(
        "Are you sure you want to disable this lab's File Drop settings? All settings will be lost once this action is completed."
      );
      if (confirm) {
        this.$emit("changeJSON", null);
        this.shouldDisable = true;
      }
    }
  },
  watch: {
    settings: {
      immediate: true,
      deep: true,
      handler(nv) {
        this.$emit("changeJSON", JSON.stringify(nv));
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
