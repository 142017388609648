<template>
  <div class="text-input">
    <label v-if="label" class="font-weight-bold" :for="$attrs.name">{{ label }}</label>
    <select
      class="form-control"
      v-bind="$attrs"
      v-on="{ ...$listeners, input: event => $emit('input', event.target.value) }"
    >
      <option hidden value="">----</option>
      <option v-for="(item, idx) in items" :key="idx" :value="item.id">
        {{ item.displayName }}
      </option>
    </select>
    <div v-if="(validator && validator.$error) || (validator && validator.$invalid)">
      <div
        :class="{ 'd-block': validator.$invalid }"
        class="validation-errors"
        v-for="(key, index) in Object.keys(validator.$params)"
        :key="index"
      >
        <span class="error mb-2" v-if="!validator[key]">
          {{ validatorMsgMap[key] }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { validatorMsgMapBase } from "@/modules/helpers";

export default {
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      required: true
    },
    label: String,
    validator: {
      required: false
    },
    validatorMsgMap: {
      type: Object,
      default: () => {
        return validatorMsgMapBase;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
