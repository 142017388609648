import { render, staticRenderFns } from "./PrintFileDropLabSettings.vue?vue&type=template&id=a6b3e700&scoped=true&"
import script from "./PrintFileDropLabSettings.vue?vue&type=script&lang=js&"
export * from "./PrintFileDropLabSettings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6b3e700",
  null
  
)

export default component.exports