<template>
  <div>
    <SelectInput label="Account Number" v-model="settings.accountNumber" :items="booleanOptions" />
    <SelectInput label="MRN" v-model="settings.mrn" :items="booleanOptions" />
    <!-- Order number is not brought in through history -->
    <!-- <SelectInput label="Order #" v-model="settings.orderNumber" :items="booleanOptions" /> -->
  </div>
</template>

<script>
import SelectInput from "./common/SelectInput.vue";

export default {
  props: ["value"],
  components: { SelectInput },
  data() {
    return {
      settings: {
        accountNumber: 1,
        mrn: 1
        // orderNumber: true
      },
      booleanOptions: [
        { displayName: "Yes", id: 1 },
        { displayName: "No", id: 0 }
      ]
    };
  },
  mounted() {
    if (this.value) {
      this.settings = { ...this.settings, ...JSON.parse(this.value) };
    }
  },
  watch: {
    settings: {
      immediate: true,
      deep: true,
      handler(nv) {
        this.$emit("changeJSON", JSON.stringify(nv));
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
