<template>
  <div>
    <pre class="options">{{ displayJson }}</pre>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    }
  },
  data() {
    return {
      isFocused: true
    };
  },
  name: "SpellCheckOptions"
};
</script>

<style lang="scss" scoped>
#spellcheckOptions {
  display: none;
}
.options {
  height: 100px;
  width: 300px;
  overflow: auto;
}
</style>
