<template>
  <div class="row edit-prostate-site pt-3 mx-0">
    <div class="col-2" v-for="n in 18" v-bind:key="n">
      <div v-if="![7, 12].includes(n)">
        <TextInput
          class="pr-0"
          :value="siteMap[n - 1].value"
          :noLabel="true"
          @input="value => updateSite(value, n - 1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/common/TextInput.vue";

export default {
  props: ["value"],
  components: {
    TextInput
  },
  data() {
    return {
      siteMap: []
    };
  },
  mounted() {
    const arrayToMap = this.value?.length ? JSON.parse(this.value) : Array(18).fill("");
    this.siteMap = arrayToMap.map(e => {
      return { value: e };
    });
  },

  watch: {
    siteMap: {
      immediate: true,
      deep: true,
      handler(nv) {
        this.$emit("changeJSON", nv ? JSON.stringify(nv.map(e => e.value)) : null);
      }
    }
  },
  methods: {
    updateSite(data, index) {
      this.siteMap[index].value = data;
    }
  }
};
</script>

<style lang="scss" scoped>
.site-entry {
  height: 5rem;
}
</style>
