<template>
  <div class="format-editor col">
    <div id="htmlToolbar">
      <select v-model="font" placeholder="Font" class="mr-2">
        <option :key="index" v-for="(font, index) in fontWhiteList" :value="font">
          {{ font }}
        </option>
      </select>
      <select v-model="size" placeholder="Size">
        <option :key="index" v-for="(size, index) in fontSizes" :value="size">
          {{ size }}
        </option>
      </select>
      <button class="btn mx-2" @click="handleSetProp('bold')" :class="{ active: bold }">
        <icon icon="bold" />
      </button>
      <button class="btn" @click="handleSetProp('italic')" :class="{ active: italic }">
        <icon icon="italic" />
      </button>
    </div>
    <p :style="editorFormatStyle">{{ text }}</p>
  </div>
</template>

<script>
import { fontWhiteList, fontSizes } from "@/modules/enums";
import Icon from "./common/Icon.vue";
import { getStyles } from "@/modules/getDefaultStylesByField";
export default {
  name: "EditorFormatLabSettingInput",
  props: {
    value: {
      default() {
        return {};
      }
    }
  },
  components: {
    Icon
  },
  data() {
    return {
      text: "Example text",
      fontWhiteList,
      fontSizes,
      font: "",
      size: "",
      bold: false,
      italic: false
    };
  },
  mounted() {
    if (this.value?.bold) {
      this.bold = true;
    }
    if (this.value?.italic) {
      this.italic = true;
    }
    if (this.value?.font) {
      this.font = this.value.font;
    }
    if (this.value?.size) {
      this.size = this.value.size;
    }
  },
  watch: {
    bold() {
      this.setValues();
    },
    italic() {
      this.setValues();
    },
    font() {
      this.setValues();
    },
    size() {
      this.setValues();
    }
  },
  computed: {
    editorFormatStyle() {
      return getStyles({
        size: this.size,
        font: this.font,
        bold: this.bold,
        italic: this.italic
      });
    }
  },
  methods: {
    initEditor({ component }) {
      component.setSelection(0, this.text.length);
    },
    setValues() {
      this.$emit("input", {
        size: this.size,
        font: this.font,
        bold: this.bold,
        italic: this.italic
      });
    },
    handleSetProp(propName) {
      if (this[propName]) {
        this[propName] = false;
      } else {
        this[propName] = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.format-editor {
  width: 300px;
}

::v-deep .dx-icon,
::v-deep .dx-dropdowneditor-icon {
  &::before {
    color: #333 !important;
  }
}
.active {
  background-color: $gray;
}
</style>
