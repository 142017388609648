<template>
  <div>
    <div>
      <button
        class="btn btn-secondary m-1 p-1 btn-small"
        v-for="token of tokens"
        v-bind:key="token.macro"
        v-tooltip="token.replacement"
        @click="insertProperty(token)"
      >
        {{ token.macro }}
      </button>
    </div>
    <TextInput
      :noLabel="true"
      :value="value"
      @input="handleValueChanged"
      :onFocus="onFocus"
      @blur="handleBlur"
      ref="textBox"
    />
    <div class="mt-1"><b>Preview:</b> {{ previewValue }}</div>
  </div>
</template>

<script>
import TextInput from "./common/TextInput.vue";
import moment from "moment";

export default {
  components: { TextInput },
  props: ["value"],
  data() {
    return {
      tokens: [
        { macro: "{ShortAccessionNum}", replacement: `T${moment().format("YY")}-12345` },
        { macro: "{AccessionNum}", replacement: `T${moment().format("YYYY")}-0012345` },
        { macro: "{FirstName}", replacement: "John" },
        { macro: "{LastName}", replacement: "Smith" },
        { macro: "{DOB}", replacement: "1970-01-01" },
        { macro: "{DateCollected}", replacement: moment().add(-7, "days").format("YYYY-MM-DD") },
        { macro: "{MRN}", replacement: "1234567" },
        { macro: "{DateReported}", replacement: moment().format("YYYY-MM-DD") }
      ],
      selectionStart: 0,
      selectionEnd: 0
    };
  },
  computed: {
    previewValue() {
      if (!this.value) {
        return "";
      }
      let previewValue = this.value;
      for (const token of this.tokens) {
        if (this.value.includes(token.macro)) {
          previewValue = previewValue.replace(token.macro, token.replacement);
        }
      }
      return previewValue;
    }
  },
  methods: {
    handleValueChanged(value) {
      this.$emit("input", value);
    },
    insertProperty(token) {
      if (!this.value) {
        this.$emit("input", token.macro);
      } else {
        const newValue =
          this.value.slice(0, this.selectionStart) +
          token.macro +
          this.value.slice(this.selectionEnd);
        this.$emit("input", newValue);
      }
      this.$refs.textBox.focus();
      this.$nextTick(() => {
        const el = document.activeElement;
        if (el) {
          const newIndex = this.selectionEnd + token.macro.length;
          el.setSelectionRange(newIndex, newIndex);
        }
      });
    },
    handleBlur({ srcElement }) {
      this.selectionStart = srcElement.selectionStart;
      this.selectionEnd = srcElement.selectionEnd;
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-small {
  font-size: 0.75rem;
}
</style>
