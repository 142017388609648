<template>
  <div>
    <checkbox
      v-for="editor of editorOptions"
      :label="displayLabel(editor)"
      :id="editor"
      :key="editor"
      :value="selectedEditors.includes(editor)"
      @input="toggleEditor(editor)"
    />
  </div>
</template>

<script>
import Checkbox from "./common/Checkbox.vue";
export default {
  components: { Checkbox },
  data() {
    return {
      editorOptions: ["diagnosis", "notes", "microscopic", "gross", "caseNotes", "clinical"],
      selectedEditors: []
    };
  },
  props: ["value", "name"],
  mounted() {
    if (this.value) {
      this.selectedEditors = this.value.split(",");
    }
  },
  methods: {
    toggleEditor(editor) {
      const index = this.selectedEditors.indexOf(editor);
      if (index > -1) {
        this.selectedEditors.splice(index, 1);
      } else {
        this.selectedEditors.push(editor);
      }
      this.$emit("changeJSON", this.selectedEditors.join(","));
    },
    displayLabel(value) {
      if (value === "notes") {
        return "specimen notes";
      }
      if (value === "caseNotes") {
        return "case notes";
      }
      return value;
    }
  }
};
</script>

<style lang="scss" scoped></style>
