<template>
  <div class="container mt-4">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="protocol">Protocol</label>
          <input type="text" class="form-control" id="protocol" v-model="innerValue.protocol" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="target">Target</label>
          <select class="form-control" id="target" v-model="innerValue.target">
            <option value="_blank" :disabled="!isHttp">New Tab</option>
            <option value="_self">Current</option>
            <option value="slideImageWindow" :disabled="!isHttp">New Window</option>
          </select>
        </div>
      </div>
    </div>
    <div v-if="isNewWindow" class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="target">Width</label>
          <input
            class="form-control"
            id="width"
            type="number"
            placeholder="width"
            v-model="innerValue.width"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="target">Height</label>
          <input
            class="form-control"
            id="height"
            type="number"
            placeholder="height"
            v-model="innerValue.height"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SlideImagesLinkHandler",
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      defaultValue: { target: "_blank", protocol: "http", width: 800, height: 600 },
      innerValue: {}
    };
  },
  created() {
    if (typeof this.value === "string") {
      try {
        this.innerValue = JSON.parse(this.value);
      } catch (e) {
        this.innerValue = this.defaultValue;
      }
    } else {
      this.innerValue = this.defaultValue;
    }
  },
  computed: {
    isHttp() {
      if (typeof this.innerValue?.protocol === "string") {
        return /http/i.test(this.innerValue.protocol);
      }
      return false;
    },
    isNewWindow() {
      if (typeof this.innerValue?.target === "string") {
        return this.innerValue.target === "slideImageWindow";
      }
      return false;
    }
  },
  watch: {
    innerValue: {
      handler(nv) {
        if (nv?.protocol) {
          if (!/http/i.test(nv.protocol)) {
            nv.target = "_self";
          }
        }
        this.$emit("input", JSON.stringify(nv));
      },
      deep: true
    }
  }
};
</script>
<style lang="scss" scoped></style>
